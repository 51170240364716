export default `
  @font-face {
    font-family: 'BrandonGrotesqueWeb-Regular';
    src: url('/static/fonts/35E86C_7_0.eot');
    src: url('/static/fonts/35E86C_7_0.eot?#iefix') format('embedded-opentype'),
      url('/static/fonts/35E86C_7_0.woff2') format('woff2'),
      url('/static/fonts/35E86C_7_0.woff') format('woff'),
      url('/static/fonts/35E86C_7_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'BrandonGrotesqueWeb-Medium';
    src: url('/static/fonts/35E86C_9_0.eot');
    src: url('/static/fonts/35E86C_9_0.eot?#iefix') format('embedded-opentype'),
      url('/static/fonts/35E86C_9_0.woff2') format('woff2'),
      url('/static/fonts/35E86C_9_0.woff') format('woff'),
      url('/static/fonts/35E86C_9_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'BrandonGrotesqueWeb-Bold';
    src: url('/static/fonts/35E86C_3_0.eot');
    src: url('/static/fonts/35E86C_3_0.eot?#iefix') format('embedded-opentype'),
      url('/static/fonts/35E86C_3_0.woff2') format('woff2'),
      url('/static/fonts/35E86C_3_0.woff') format('woff'),
      url('/static/fonts/35E86C_3_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'BrandonGrotesqueWeb-Black';
    src: url('/static/fonts/35E86C_4_0.eot');
    src: url('/static/fonts/35E86C_4_0.eot?#iefix') format('embedded-opentype'),
      url('/static/fonts/35E86C_4_0.woff2') format('woff2'),
      url('/static/fonts/35E86C_4_0.woff') format('woff'),
      url('/static/fonts/35E86C_4_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'ghd';
    src:  url('/static/fonts/ghd.eot?ebzfk5');
    src:  url('/static/fonts/ghd.eot?ebzfk5#iefix') format('embedded-opentype'),
      url('/static/fonts/ghd.ttf?ebzfk5') format('truetype'),
      url('/static/fonts/ghd.woff?ebzfk5') format('woff'),
      url('/static/fonts/ghd.svg?ebzfk5#ghd') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Francoeur';
    src: url('/static/fonts/Francoeur.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Francoeur';
    src: url('/static/fonts/Francoeur-bold.ttf') format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Francoeur';
    src: url('/static/fonts/Francoeur-light.ttf') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Francoeur';
    src: url('/static/fonts/Francoeur-semibold.ttf') format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Gustavo';
    src: url('/static/fonts/Gustavo-Regular.otf') format('opentype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Gustavo';
    src: url('/static/fonts/Gustavo-Thin.otf') format('opentype');
    font-weight: 100;
  }

  @font-face {
    font-family: 'Helvetica';
    src: url('/static/fonts/Helvetica.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Helvetica';
    src: url('/static/fonts/Helvetica-Light.ttf') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Helvetica';
    src: url('/static/fonts/Helvetica-Bold.ttf') format('truetype');
    font-weight: 700;
  }

  [class^="icon-"], [class*=" icon-"] {
    font-family: 'ghd' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-access:before {
    content: "\\e900";
  }
  .icon-arrow-right:before {
    content: "\\e901";
    color: #fb5237;
  }
  .icon-audio-guide:before {
    content: "\\e902";
    color: #fff;
  }
  .icon-blog:before {
    content: "\\e903";
  }
  .icon-car:before {
    content: "\\e904";
    color: #2d3155;
  }
  .icon-chevron-left:before {
    content: "\\e905";
    color: #fb5237;
  }
  .icon-chevron-right:before {
    content: "\\e906";
    color: #fb5237;
  }
  .icon-distributor:before {
    content: "\\e907";
    color: #fff;
  }
  .icon-enigma:before {
    content: "\\e908";
    color: #fff;
  }
  .icon-event:before {
    content: "\\e909";
    color: #fff;
  }
  .icon-user:before {
    content: "\\e95a";
  }
  .icon-facebook:before {
    content: "\\e90a";
  }
  .icon-instagram:before {
    content: "\\e90b";
  }
  .icon-logo .path1:before {
    content: "\\e90c";
    color: rgb(255, 255, 255);
  }
  .icon-logo .path2:before {
    content: "\\e90d";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path3:before {
    content: "\\e90e";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path4:before {
    content: "\\e90f";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path5:before {
    content: "\\e910";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path6:before {
    content: "\\e911";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path7:before {
    content: "\\e912";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path8:before {
    content: "\\e913";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path9:before {
    content: "\\e914";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path10:before {
    content: "\\e915";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path11:before {
    content: "\\e916";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path12:before {
    content: "\\e917";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path13:before {
    content: "\\e918";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path14:before {
    content: "\\e919";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path15:before {
    content: "\\e91a";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path16:before {
    content: "\\e91b";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path17:before {
    content: "\\e91c";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path18:before {
    content: "\\e91d";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path19:before {
    content: "\\e91e";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path20:before {
    content: "\\e91f";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path21:before {
    content: "\\e920";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path22:before {
    content: "\\e921";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path23:before {
    content: "\\e922";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path24:before {
    content: "\\e923";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path25:before {
    content: "\\e924";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path26:before {
    content: "\\e925";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path27:before {
    content: "\\e926";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path28:before {
    content: "\\e927";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path29:before {
    content: "\\e928";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path30:before {
    content: "\\e929";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path31:before {
    content: "\\e92a";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path32:before {
    content: "\\e92b";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path33:before {
    content: "\\e92c";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path34:before {
    content: "\\e92d";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path35:before {
    content: "\\e92e";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path36:before {
    content: "\\e92f";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path37:before {
    content: "\\e930";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path38:before {
    content: "\\e931";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path39:before {
    content: "\\e932";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path40:before {
    content: "\\e933";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path41:before {
    content: "\\e934";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
    opacity: 0.7;
  }
  .icon-logo .path42:before {
    content: "\\e935";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path43:before {
    content: "\\e936";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path44:before {
    content: "\\e937";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path45:before {
    content: "\\e938";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path46:before {
    content: "\\e939";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path47:before {
    content: "\\e93a";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path48:before {
    content: "\\e93b";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path49:before {
    content: "\\e93c";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path50:before {
    content: "\\e93d";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path51:before {
    content: "\\e93e";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path52:before {
    content: "\\e93f";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path53:before {
    content: "\\e940";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-logo .path54:before {
    content: "\\e941";
    margin-left: -1.4853515625em;
    color: rgb(255, 255, 255);
  }
  .icon-long-arrow-right:before {
    content: "\\e942";
    color: #fb5237;
  }
  .icon-other:before {
    content: "\\e943";
  }
  .icon-pinterest:before {
    content: "\\e959";
  }
  .icon-public-transport:before {
    content: "\\e945";
    color: #2d3155;
  }
  .icon-restaurant:before {
    content: "\\e946";
  }
  .icon-schedule:before {
    content: "\\e947";
  }
  .icon-search:before {
    content: "\\e948";
    color: #fff;
  }
  .icon-services:before {
    content: "\\e949";
  }
  .icon-shopping:before {
    content: "\\e94a";
  }
  .icon-twitter:before {
    content: "\\e94b";
  }
  .icon-wifi:before {
    content: "\\e94c";
    color: #fff;
  }
  .icon-filter:before {
    content: "\\e94d";
    color: #fb5237;
  }
  .icon-close:before {
    content: "\\e94e";
    color: #fb5237;
  }
  .icon-telephone:before {
    content: "\\e94f";
  }
  .icon-plan:before {
    content: "\\e950";
  }
  .icon-megaphone:before {
    content: "\\e951";
  }
  .icon-tag:before {
    content: "\\e952";
  }
  .icon-parking:before {
    content: "\\e953";
  }
  .icon-projet:before {
    content: "\\e956";
  }
  .icon-video:before {
    content: "\\e960";
  }
  .icon-discover:before {
    content: "\\e954";
  }
  .icon-word:before {
    content: "\\e955";
  }
  .icon-calendar:before {
    content: "\\e958";
  }
  .icon-linkedin:before {
    content: "\\e944";
  }
  .icon-google:before {
    content: "\\e95f";
  }
`;
