import { createGlobalStyle } from 'styled-components';

import font from './../theme/font';

import importFont from './fonts';

export const GlobalStyle = createGlobalStyle`
    ${importFont}

    * {
        box-sizing: border-box;
        min-height: 0;
        min-width: 0;
    }

    html {
        font-size: 62.5%;
    }

    body {
        position: relative;
        height: auto;

        font-family: ${font.brand.primary};
        font-size: ${font.body.fontSize};
        line-height: ${font.body.lineHeight};
        font-weight: ${font.fontWeight};

        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;

        overflow-x: hidden;

        > *:first-child {
            overflow: hidden;
        }
    }

    .no-scroll {
        position: relative;
        height: 100vh;

        overflow-y: hidden;
    }
`;
