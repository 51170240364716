import App, { Container } from "next/app";
import React from "react";
import { ApolloProvider } from "react-apollo";
import { I18nextProvider } from "react-i18next";
import withApolloClient from "../src/hoc/with-apollo-client";
import withSentry from "../src/hoc/withSentry";
import i18nR from "../src/utils/I18N/index";
import Error from "../src/components/Error";
import { GlobalStyle } from "../src/styles/global-styles.js";
import { Reset } from "styled-reset";

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};
    const i18nInitialProps = ctx.req
      ? i18nR.getInitialProps(ctx.req, "common")
      : {};

    return { pageProps, i18nInitialProps };
  }

  componentDidMount() {
    if ("serviceWorker" in navigator && process.env.SERVICE_WORKER_ENABLE) {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then(registration => {
          // eslint-disable-next-line no-console
          console.log("service worker registration successful", registration);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.warn("service worker registration failed", err.message);
        });
    }
  }

  render() {
    const {
      Component,
      pageProps,
      apolloClient,
      i18nInitialProps,
      error,
      apolloError
    } = this.props;

    if (error || apolloError) {
      const statusCode =
        (error && error.statusCode) ||
        (apolloError && apolloError.statusCode) ||
        500;
      const message =
        (error && error.message) || (apolloError && apolloError.message) || "";

      return (
        <Error statusCode={statusCode} message={message} {...this.props} />
      );
    }

    const { i18n, initialI18nStore, initialLanguage } = i18nInitialProps;
    return (
      <Container>
        <Reset />
        <GlobalStyle />
        <ApolloProvider client={apolloClient}>
          <I18nextProvider
            i18n={i18n || i18nR}
            initialI18nStore={initialI18nStore}
            initialLanguage={initialLanguage}
          >
            <Component {...pageProps} />
          </I18nextProvider>
        </ApolloProvider>
      </Container>
    );
  }
}
export default withSentry(withApolloClient(MyApp));
